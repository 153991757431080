export const company = {
  seotitle: 'Ace Budget - Your Trusted Taxi Service in Bradford',
  seodescription: `Ace Budget, serving Fairweather Green, Allerton, Thornton, Clayton, Girlington, Sandy Lane.`,
  name: 'Ace Budget',
  address: {
    building: '1001',
    street: 'Thornton Road',
    town: 'Bradford, West Yorkshire',
    postcode: 'BD8 0JD',
  },
  cookies_name: '',
  apptitle: 'Ace Budget App',
  apple_store_id: 'app-id=id1171116619',
  google_store_id: 'app-id=com.autocab.taxibooker.shipleycentraltaxis',
  apple_store_url: 'https://apps.apple.com/gb/app/shipley-taxis/id1171116619',
  google_store_url:
    'https://play.google.com/store/apps/details?id=com.autocab.taxibooker.shipleycentraltaxis.shipley&hl=en_GB',
  company_email: 'acebudget1818@gmail.com',
  tel_number: '01274 481818',
  dpo_email: 'acebudget1818@gmail.com',
  dpo: '',
  vat_number: '-',
  company_number: '',
  website: 'www.acebudget.co.uk',
  url: 'https://www.acebudget.co.uk.co.uk',
  dpo_reg_number: '-',
  updated_on: '17/09/2023',
  Copyright: 'Copyright 2023 | Ace Budget | All Rights Reserved',
  logo_url: '/assets/images/logo.png',
  logo_alt: 'Ace Budget Logo',
  width: '180',
  height: '70',
  width_mobile: '180',
  height_mobile: '70',
  contactus_task_url: 'https://',
  Facebook: 'https://www.facebook.com/shipleycentraltaxis/?locale=en_GB',
  Instagram: 'https://www.instagram.com/shipleycentral/?hl=en',
  Twitter:
    'https://twitter.com/i/flow/login?redirect_after_login=%2Fshipleytaxis',
  LinkedIn: 'https://www.linkedin.com/company/shipley-central-taxis/about/',
};
// Check List - make sure update below files before making this website live
// 1- robots_txt: 'update robots.txt file domain details
// 1-   robots_txt: 'update robots.txt file domain details
// 2-  regenarate manifest.json file and icons and put in public folder
