
require("./../node_modules/@module-federation/nextjs-mf/src/include-defaults");
import Head from 'next/head';
import './globals.css';
import Link from 'next/link';
import Layout from '@components/Layout';
import FeedbackWrapper from '@components/wrapper/FeedbackWrapper';
import { StyledEngineProvider } from '@mui/styled-engine';
import { ThemeProvider } from '@mui/system';
import CookieConsent from 'react-cookie-consent';
import theme from '../theme';
import Script from 'next/script';

export default function App({ Component, pageProps }) {
  return (
    <>
      <Script
        strategy="lazyOnload"
        src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}`}
      />

      <Script id="google_analytics" strategy="lazyOnload">
        {`
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', '${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}', {
    page_path: window.location.pathname,
    });
`}
      </Script>
      <Head>
        <link rel="manifest" href="/manifest.json" />
        <link rel="apple-touch-icon" href="/icon.png"></link>
        <link
          href="https://unpkg.com/aos@2.3.1/dist/aos.css"
          rel="stylesheet"
        ></link>
        <meta name="theme-color" content="#58ade0" />
      </Head>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <CookieConsent
            location="bottom"
            buttonText="ACCEPT"
            cookieName="AceBudgetCenralTaxiCookie"
            style={{ background: '#fece00' }}
            buttonStyle={{
              background: 'green',
              color: 'white',
              fontSize: '13px',
              fontWeight: 'bold',
            }}
            expires={150}
          >
            <span className="text-black">
              We are using cookies to give you the best experience on our
              website. You can find out more about our cookie policy
            </span>
            <Link href="/legal/policy/cookie">
              <a className="text-secondary font-bold"> here.</a>
            </Link>
          </CookieConsent>
          <Layout>
            <Component {...pageProps} />
          </Layout>
          <FeedbackWrapper />
        </ThemeProvider>
      </StyledEngineProvider>
    </>
  );
}
